.skill-card {
    background-color: black;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .skill-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .skill-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .skill-list li {
    margin-bottom: 5px;
  }