svg {
    stroke-dasharray: 3000;
    animation: 5s write infinite;
  }
  
  @keyframes write {
    0% {
      stroke-dashoffset: 3000;
    }
    100% {
      stroke-dashoffset: 1500;
    }
  }